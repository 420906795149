import Box from "@mui/material/Box";
import {LinkButton} from "@components/LinkButton";
import {joinedCampaignListBotRoute} from "@components/ReferralBot/JoinedCampaign/router";
import {t} from "i18next";
import {Button} from "@mui/material";
import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import CampaignIcon from "@mui/icons-material/Campaign";
import {ownCampaignListBotRoute} from "@components/ReferralBot/OwnCampaign/router";
import {useAtom} from "jotai/index";
import {headerTitleAtom} from "@components/ReferralBot/State/HeaderTitle";

export const HomeScreen = () => {
    const [, setTitle] = useAtom(headerTitleAtom)
    setTitle(t('referralBot.homePage.title'))

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
            <Button
                component={LinkButton}
                startIcon={<GroupIcon />}
                to={joinedCampaignListBotRoute.id}
                variant="outlined"
                color="primary"
                fullWidth
                sx={{m: 1}}
            >
                {t('referralBot.homeScreen.myReferrals')}
            </Button>
            <Button
                component={LinkButton}
                startIcon={<CampaignIcon />}
                to={ownCampaignListBotRoute.id}
                variant="outlined"
                color="primary"
                fullWidth
                sx={{m: 1}}
            >
                {t('referralBot.homeScreen.myCampaigns')}
            </Button>
        </Box>
    )
}
