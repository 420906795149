import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as z from 'zod'
import { t } from 'i18next'

import { FormControlLabel, FormHelperText, Grid, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { CURRENCY_SYMBOL } from '@components/ReferralBot/Constant'
import { CampaignType, getCampaignTypeValues } from '@components/ReferralBot/Type/CampaignType'
import SaveIcon from '@mui/icons-material/Save'
import { BOT_GUIDE_LINK } from '@framework/constants'

const validationSchema = z.object({
  name: z.string().min(1).max(50),
  rewardPerUser: z.coerce.number().min(0.001).max(0.5),
  type: z.coerce.number(), // todo use enum
})

export type NewCampaignType = z.infer<typeof validationSchema>

interface Props {
  onSave: (newGreeting: NewCampaignType) => void
  loading: boolean
  data?: NewCampaignType,
  disabled?: boolean
  isBotTypeDisabled?: boolean
}

export function Form({ onSave, loading, data, disabled, isBotTypeDisabled }: Props) {
  const defaultData: NewCampaignType = data || {
    rewardPerUser: 0.001,
    name: '',
    type: CampaignType.CHANNEL,
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewCampaignType>({
    defaultValues: defaultData,
    resolver: zodResolver(validationSchema),
  })

  if (Object.keys(errors).length > 0) {
    console.debug('Validation errors: ', errors)
  }

  return (
    <form
      onSubmit={handleSubmit(onSave)}
      id="create-own-campaign"
      aria-disabled={disabled}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="name"
                label={t('referralBot.newOwnCampaignForm.name')}
                variant="outlined"
                error={!!errors.name}
                helperText={String(errors.name?.message || '')}
                disabled={loading || disabled}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Controller
            name="rewardPerUser"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">{CURRENCY_SYMBOL}</InputAdornment>,
                }}
                id="rewardPerUser"
                label={t('referralBot.newOwnCampaignForm.rewardPerUser')}
                variant="outlined"
                error={!!errors.rewardPerUser}
                helperText={String(errors.rewardPerUser?.message || '')}
                disabled={loading || disabled}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <>
                <RadioGroup
                  {...field}
                >
                  {getCampaignTypeValues().map((type) => (
                    <FormControlLabel
                      disabled={isBotTypeDisabled && type === CampaignType.BOT}
                      key={type}
                      value={type}
                      control={<Radio />}
                      label={t(`referralBot.newOwnCampaignForm.type_${type}`)}
                    />
                  ))}
                </RadioGroup>
                {errors.type?.message && <FormHelperText error>{errors.type.message}</FormHelperText>}
              </>
            )}
          />
          {isBotTypeDisabled && (
            <FormHelperText>
              <a href={BOT_GUIDE_LINK} target="_blank">{t(`referralBot.newOwnCampaignForm.learnHowToConnectBot`)}</a>
            </FormHelperText>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'center' }}
        >
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            type="submit"
            disabled={disabled}
          >
            {t(`referralBot.save`)}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}
