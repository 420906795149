import * as React from 'react'


import Container from '@mui/material/Container'
import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { pageRoute } from '@components/Site/Page/router'
import { fetchPage } from '@framework/api/page.api'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

export const ViewPage = () => {
  const {slug} = useParams<typeof pageRoute>({
    from: pageRoute.id,
  })
  const { data: pageDto, isFetching } = useQuery({
    queryKey: ['pages', slug],
    queryFn: () => fetchPage(slug),
  })

  return <>
    <Container>
      {isFetching && <LinearProgress />}
      {pageDto && (
        <>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            {pageDto.title}
          </Typography>
         <div dangerouslySetInnerHTML={{ __html: pageDto.content }} />
        </>
      )}
    </Container>
  </>
}
