import { Route } from '@tanstack/react-router'
import { List } from '@components/ReferralBot/OwnCampaign/List'
import { Add } from '@components/ReferralBot/OwnCampaign/Add'
import { Edit } from '@components/ReferralBot/OwnCampaign/Edit'
import { ConnectBot } from '@components/ReferralBot/OwnCampaign/ConnectBot'
import { Status } from '@components/ReferralBot/OwnCampaign/Status'
import { Start } from '@components/ReferralBot/OwnCampaign/Start'
import { Finish } from '@components/ReferralBot/OwnCampaign/Finish'
import { Fund } from '@components/ReferralBot/OwnCampaign/Fund'

import { referralBotRoute } from '../router'
import { SetBotUsername } from '@components/ReferralBot/OwnCampaign/SetBotUsername'

export const ownCampaignListBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/list',
  component: List,
})

export const ownCampaignAddBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/add',
  component: Add,
})
export const ownCampaignEditBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/edit/$uuid',
  component: Edit,
})

export const ownCampaignConnectBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/connect/$uuid',
  component: ConnectBot,
})

export const ownCampaignSetBotUsernameRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/set-bot-username/$uuid',
  component: SetBotUsername,
})

export const ownCampaignStatusRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/status/$uuid',
  component: Status,
})

export const ownCampaignStartRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/start/$uuid',
  component: Start,
})

export const ownCampaignFinishRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/finish/$uuid',
  component: Finish,
})

export const ownCampaignFundRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/own-campaigns/fund/$uuid',
  component: Fund,
})
