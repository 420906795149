import { RootRoute, Route } from '@tanstack/react-router'
import LandingPage from '@components/Site/Landing/LandingPage'
import { pageRoute } from '@components/Site/Page/router'
import { LayoutContainer } from '@ui/containers/Layout/LayoutContainer'

export const rootRoute = new RootRoute({
  component: LayoutContainer,
})

export const siteRouter = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component: () => <LandingPage />,
})
siteRouter.addChildren([pageRoute])
