import * as React from 'react'
import { Box } from '@mui/material'

interface Props {
  children: React.ReactNode
}

export const CampaignCreateStepperFooter = ({children}: Props) => {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        pt: 2,
        mt: 3,
        borderTop: '1px solid #eaecf0',
      }}
      >
        {children}
      </Box>
    );
}
