import { t } from 'i18next'

import { CURRENCY_SYMBOL } from '@components/ReferralBot/Constant'
import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'

export const formatCurrency = (amout: string): string => `${amout} ${CURRENCY_SYMBOL}`

export const accessInnerProperty = (obj: NonNullable<unknown>, propertyPath: string) => {
  const properties = propertyPath.split('.')
  let currentObj = obj

  for (let i = 0; i < properties.length; i++) {
    if (currentObj[properties[i]] !== undefined) {
      currentObj = currentObj[properties[i]]
    } else {
      return undefined
    }
  }

  return currentObj
}

export const getErrorMessage = (error: unknown) => {
  console.error(error)
  let message = error && typeof error === 'object' && 'message' in error ? `${error.message}` : t('error')

  if (error && typeof error === 'object') {
    const certainMessage = accessInnerProperty(error, 'response.data.message')

    if (certainMessage) {
      message = String(certainMessage)
    }
  }

  return message
}

export const hasCampaignEnoughBudget = (campaign: CampaignDto): boolean => {
  return Number(campaign.budget) < campaign.minBudget;
}

export const canStartCampaign = (campaign: CampaignDto): boolean => {
  if (campaign?.status !== CampaignStatus.INACTIVE) {
    return false;
  }

  switch (campaign?.type) {
    case CampaignType.CHANNEL:
      return Boolean(campaign?.isBotConnected);
    case CampaignType.BOT:
      return campaign?.botUsername !== null;
    default:
      return false;
  }
}
