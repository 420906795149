import { DateTime } from 'luxon'

export const formatDateTime = (date: string | null | Date): string => {
  if (!date) {
    return ''
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_MED)
  }

  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
}
