import { createRouter } from '@tanstack/react-router'
import { rootRoute, siteRouter } from '@components/Site/siteRouter'
import { referralBotRoute } from '@components/ReferralBot/router'

const routes = [
  siteRouter,
  referralBotRoute,
]

const routeTree = rootRoute.addChildren(routes)

export const router = createRouter({ routeTree })

// Register your router for maximum type safety
declare module '@tanstack/react-router' {
  interface Register {
    // @ts-ignore
    router: typeof router
  }
}
