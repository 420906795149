import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {BOT_GUIDE_LINK} from "@framework/constants";
import Button from "@mui/material/Button";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Box from "@mui/material/Box";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
          sx={{
            // width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
          sx={{
            // width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          Need Help? Ask Our Assistant!
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{
          textAlign: { sm: 'left', md: 'center' },
          mt: 3,
        }}>
          Get quick and reliable answers to all your questions with our assistant.<br />
          <Button startIcon={<ContactSupportIcon />} sx={{mt: 3}} href={BOT_GUIDE_LINK} target="_blank" variant="outlined" color="primary">
            Get help
          </Button>
        </Typography>
      </Box>
    </Container>
  );
}
