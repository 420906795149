import { enUS, ruRU, ukUA } from '@mui/material/locale';

export const LANGUAGES = {
  EN: 'en',
  RU: 'ru',
  UK: 'uk',
} as const;

export const LOCALES = {
  [LANGUAGES.EN]: 'en-GB',
  [LANGUAGES.RU]: 'ru-RU',
  [LANGUAGES.UK]: 'uk-UA',
} as const;

export const LOCALIZATIONS = {
  [LANGUAGES.EN]: enUS,
  [LANGUAGES.RU]: ruRU,
  [LANGUAGES.UK]: ukUA,
} as const;
