import React from 'react'
import { t } from 'i18next'
import { useAtom } from 'jotai/index'
import { useSnackbar } from 'notistack'

import { useParams } from '@tanstack/react-router'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { TopActions } from '@components/ReferralBot/TopActions'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {fetchCampaignMe} from '@framework/api/referral-bot.api'
import {meCampaignDetailsBotRoute, meCampaignsListBotRoute} from "@components/ReferralBot/Me/router";
import {CustomTable} from "@components/ReferralBot/Table/CustomTable";
import {
  meCampaignDetailsToTableRows,
  referrerDetailsToTableRows
} from "@components/ReferralBot/helpers/custom-table.helper";
import {WithdrawBudgetWidget} from "@components/ReferralBot/Me/WithdrawBudgetWidget";

export const CampaignDetails = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const {uuid} = useParams<typeof meCampaignDetailsBotRoute>({
    from: meCampaignDetailsBotRoute.id
  })

  const { data: campaignDetails, isLoading: isFetchingCampaign } = useQuery({
    queryKey: ['campaign_me', uuid],
    queryFn: () => fetchCampaignMe(uuid),
  })

  const [, setTitle] = useAtom(headerTitleAtom)
  setTitle('Campaign details [ME]');


  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={meCampaignsListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      {campaignDetails && <CustomTable rows={meCampaignDetailsToTableRows(campaignDetails)} />}
      {campaignDetails && <WithdrawBudgetWidget campaign={campaignDetails} /> }
    </>
  )
}
