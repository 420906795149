import * as React from 'react'
import { Grid, PaletteMode } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Outlet, useRouter } from '@tanstack/react-router'
import AppAppBar from '@components/Site/Landing/AppAppBar'
import Hero from '@components/Site/Landing/Hero'
import Footer from '@components/Site/Landing/Footer'
import FAQ from '@components/Site/Landing/FAQ'
import { useWindowsSize } from '@framework/hooks'

export const LayoutContainer = () => {
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const router = useRouter()
  const currentPath = router.state.location.pathname
  const isReferralBot = currentPath.includes('/referral-bot')
  const { width } = useWindowsSize()

  // layout for referral bot
  if (isReferralBot) {
    return (
      <>
        <Grid
          container
          maxWidth="xl"
          style={{ maxWidth: width }}
        >
          <Outlet />
        </Grid>
      </>
    )
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{ bgcolor: 'background.default', width: '100%' }}>
        <Outlet />
        <Divider />
        <FAQ />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
