import {get, patch, post} from '@framework/api/axios'
import { NewCampaignType } from '@components/ReferralBot/OwnCampaign/Form'
import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import { CampaignStatusDto } from '@components/ReferralBot/Type/CampaignStatusDto'
import { ReferrerJoinedCampaign } from '@components/ReferralBot/Type/ReferrerJoinedCampaign'
import { ReferrerDto } from '@components/ReferralBot/Type/ReferrerDto'
import { CampaignBudget } from '@components/ReferralBot/Type/CampaignBudget'
import { UserProfile } from '@components/ReferralBot/Type/UserProfile'
import { PaymentTransaction } from '@components/ReferralBot/Type/PaymentTransaction'
import { ReferrerDetails } from '@components/ReferralBot/Type/ReferrerDetails'
import {CampaignMeDto} from "@components/ReferralBot/Type/CampaignMeDto";
import { UpdateCampaignBotType } from '@components/ReferralBot/OwnCampaign/SetBotUsername'

// todo move it into env
// const domain = 'http://127.0.0.1:4001'
// const domain = 'https://9bbehx7hgf.execute-api.eu-central-1.amazonaws.com/prod'

// const patch = async <T>(url: string, data?: any, config: any = {}): Promise<T> => {
//   const response = await axiosInstance.patch(`${domain}${url}`, data, {
//     ...config,
//     withCredentials: true,
//   })
//   return response.data
// }
//
// const post = async <T>(url: string, data?: any, config: any = {}): Promise<T> => {
//   const response = await axiosInstance.post(`${domain}${url}`, data, {
//     ...config,
//     withCredentials: true,
//   })
//   return response.data
// }
//
// const get = async <T>(url: string, config: any = {}): Promise<T> => {
//   const response = await axiosInstance.get(`${domain}${url}`, config)
//   return response.data
// }

const getTelegramHeaders = () => ({
  'bypass-tunnel-reminder': 'any',
  // @ts-ignore
  'telegram-init-data': window?.Telegram?.WebApp?.initData,
})

export const createCampaign = async (payload: NewCampaignType): Promise<CampaignDto> => {
  return await post('/telegram-bot-mini-app/api/campaigns', payload, {
    headers: getTelegramHeaders(),
  })
}

type PatchCampaignType = NewCampaignType & {
  uuid: string
}

type PatchCampaignBotType = UpdateCampaignBotType & {
  uuid: string
}

export const pathCampaignBot = ({ uuid, ...payload }: PatchCampaignBotType) => {
  return patch(`/telegram-bot-mini-app/api/campaigns/${uuid}/bot`, payload, {
    headers: getTelegramHeaders(),
  })
}

export const pathCampaign = ({ uuid, ...payload }: PatchCampaignType) => {
  return patch(`/telegram-bot-mini-app/api/campaigns/${uuid}`, payload, {
    headers: getTelegramHeaders(),
  })
}

export const fetchCampaigns = async (): Promise<CampaignDto[]> => {
  return get('/telegram-bot-mini-app/api/campaigns', {}, getTelegramHeaders())
}

export const fetchCampaign = async (uuid: string): Promise<CampaignDto | null> => {
  return get(`/telegram-bot-mini-app/api/campaigns/${uuid}`, {}, getTelegramHeaders())
}

export const startCampaign = async (uuid: string): Promise<CampaignDto> => {
  return patch(
    `/telegram-bot-mini-app/api/campaigns/${uuid}/start`,
    {},
    {
      // todo move it into one
      headers: getTelegramHeaders(),
    }
  )
}

export const finishCampaign = async (uuid: string): Promise<CampaignDto> => {
  return patch(
    `/telegram-bot-mini-app/api/campaigns/${uuid}/finish`,
    {},
    {
      // todo move it into one
      headers: getTelegramHeaders(),
    }
  )
}

export const fetchCampaignConnectCode = async (uuid: string): Promise<{ connectCommand: string }> => {
  return get(`/telegram-bot-mini-app/api/campaigns/${uuid}/connect`, {},getTelegramHeaders())
}

export const fetchCampaignStatus = async (uuid: string): Promise<CampaignStatusDto> => {
  return get(`/telegram-bot-mini-app/api/campaigns/${uuid}/status`, {}, getTelegramHeaders())
}

export const fetchReferrers = async (): Promise<ReferrerJoinedCampaign[]> => {
  return get('/telegram-bot-mini-app/api/referrers', {}, getTelegramHeaders())
}

export const fetchReferrer = async (uuid: string): Promise<ReferrerDto> => {
  return get(`/telegram-bot-mini-app/api/referrers/${uuid}`, {}, getTelegramHeaders())
}

export const fetchCampaignBudget = async (uuid: string): Promise<CampaignBudget> => {
  return get(`/telegram-bot-mini-app/api/campaigns/${uuid}/budget`, {}, getTelegramHeaders())
}

export const fetchUserProfile = async (): Promise<UserProfile> => {
  return get(`/telegram-bot-mini-app/api/users/me`, {}, getTelegramHeaders())
}

export const withdrawBalance = async (payload: { walletAddress: string }): Promise<void> => {
  await post('/telegram-bot-mini-app/api/users/withdraw-balance', payload, {
    headers: getTelegramHeaders(),
  })
}

export const fetchBalanceTransactions = async (): Promise<PaymentTransaction[]> => {
  return get('/telegram-bot-mini-app/api/users/balance-transactions', {}, getTelegramHeaders())
}

export const fetchReferrerDetails = async (uuid: string): Promise<ReferrerDetails> => {
  return get(`/telegram-bot-mini-app/api/referrers/${uuid}`, {}, getTelegramHeaders())
}

export const fetchCampaignsMe = async (): Promise<CampaignDto[]> => {
  return get('/telegram-bot-mini-app/api/me/campaigns', {}, getTelegramHeaders())
}

export const fetchCampaignMe = async (uuid: string): Promise<CampaignMeDto | null> => {
  return get(`/telegram-bot-mini-app/api/me/campaigns/${uuid}`, {}, getTelegramHeaders())
}

export const withdrawCampaignBudgetMe = async (uuid: string): Promise<void> => {
  return post(
      `/telegram-bot-mini-app/api/me/campaigns/${uuid}/withdraw`,
      {},
      {
        headers: getTelegramHeaders(),
      }
  )
}
