import React from 'react'

import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'

interface Props {
  sx?: SxProps<Theme>
}

export const TonIcon = ({ sx }: Props) => {
    // const imgUrl = new URL('./images/ton_symbol.svg', import.meta.url).href
    const imgUrl = new URL('./img/ton_symbol.png', import.meta.url).href
    return (
    <Box
      sx={{
        display: 'inline-block',
        backgroundImage: `url("${imgUrl}")`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        maxWidth: '50px',
        maxHeight: '50px',
        minHeight: '15px',
        minWidth: '15px',
        ...sx,
      }}
    />
  )
}
