import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { Form, NewCampaignType } from '@components/ReferralBot/OwnCampaign/Form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createCampaign } from '@framework/api/referral-bot.api'
import { useNavigate } from '@tanstack/react-router'
import { ownCampaignConnectBotRoute, ownCampaignSetBotUsernameRoute } from '@components/ReferralBot/OwnCampaign/router'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { CampaignCreateStepper } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepper'
import { useAtom } from 'jotai/index'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'

export const Add = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { mutate, isPending: isCreating } = useMutation({
    mutationFn: createCampaign,
  })
  const [, setTitle] = useAtom(headerTitleAtom)

  useEffect(() => {
    setTitle(t('referralBot.ownCampaigns.createCampaign'))
    return () => setTitle('')
  }, [])

  const navigate = useNavigate()
  const onSave = (payload: NewCampaignType) => {
    mutate(payload, {
      onSuccess: async (data) => {
        enqueueSnackbar(t('referralBot.newOwnCampaignForm.created'), { variant: 'success' })

        await queryClient.invalidateQueries({
          queryKey: ['campaigns']
        })

        if (data.type === CampaignType.BOT) {
          await navigate({ to: ownCampaignSetBotUsernameRoute.id, params: { uuid: data.uuid } })
        } else {
          await navigate({ to: ownCampaignConnectBotRoute.id, params: { uuid: data.uuid } })
        }
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  return (
    <>
      <CampaignCreateStepper activeStep={0} />
      <Form
        loading={isCreating}
        onSave={onSave}
        isBotTypeDisabled={true}
      />
    </>
  )
}
