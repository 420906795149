import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LANGUAGES } from '@framework/constants/languages';

import en from './en.json';
import ru from './ru.json';
import uk from './uk.json';

const telegramLanguageDetector = {
    name: 'telegramLanguageDetector',
    lookup(options) {
        console.log('window?.Telegram?.WebApp ', window?.Telegram?.WebApp)

        const initData = window?.Telegram?.WebApp?.initData

        if (!initData) {
            return undefined;
        }

        try {
            const urlParams = new URLSearchParams(initData);
            const jsonUser = urlParams.get('user')
            const user = jsonUser ? JSON.parse(jsonUser) : undefined;

            return user?.language_code;
        } catch (e) {
            console.error('Error parsing Telegram initData', e)
        }

        return undefined;
    },
    cacheUserLanguage(lng, options) {
        // options -> are passed in options
        // lng -> current language, will be called after init and on changeLanguage
        // store it
    },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(telegramLanguageDetector);

// Initialize i18next with the custom language detector
i18n
    .use(initReactI18next)
    .use(languageDetector)
    .init({
        supportedLngs: [LANGUAGES.EN, LANGUAGES.RU, LANGUAGES.UK],
        fallbackLng: LANGUAGES.EN,
        debug: import.meta.env.DEV,
        detection: {
            order: ['telegramLanguageDetector', 'querystring', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage'],
        },
        resources: {
            en: {
                translation: en,
            },
            ru: {
                translation: ru,
            },
            uk: {
                translation: uk,
            },
        },
        react: { useSuspense: true },
    });

i18n.languages = [LANGUAGES.EN, LANGUAGES.RU, LANGUAGES.UK];

export default i18n;
