import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useAtom } from 'jotai/index'

import { useParams } from '@tanstack/react-router'
import { ownCampaignFundRoute, ownCampaignStartRoute } from '@components/ReferralBot/OwnCampaign/router'
import { Alert, Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchCampaign, startCampaign } from '@framework/api/referral-bot.api'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import { StatusTable } from '@components/ReferralBot/OwnCampaign/StatusTable'
import { canStartCampaign, getErrorMessage, hasCampaignEnoughBudget } from '@components/ReferralBot/helpers'
import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import CheckIcon from '@mui/icons-material/Check'
import { ConfirmDialog } from '@components/ReferralBot/Confirm'
import { CampaignCreateStepper } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepper'
import { CampaignCreateStepperFooter } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepperFooter'

export const Start = () => {
  const [confirmDialog, setConfirmDialog] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [, setTitle] = useAtom(headerTitleAtom)
  const queryClient = useQueryClient()
  const { uuid } = useParams<typeof ownCampaignStartRoute>({
    from: ownCampaignStartRoute.id,
  })
  const {
    data: campaign,
    isPending: isLoadingCampaign,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })

  const { mutate: startCampaignMutate, isPending: isStartingCampaign } = useMutation({
    mutationFn: startCampaign,
  })

  const startCampaignClick = () => {
    setConfirmDialog(false)

    startCampaignMutate(uuid, {
      onSuccess: async () => {
        enqueueSnackbar(t('referralBot.campaignsList.campaignStarted'), { variant: 'success' })
        await queryClient.invalidateQueries({
            queryKey: ['campaigns', uuid]
        })
        await queryClient.invalidateQueries({
          queryKey: ['campaigns']
        })
        await refetch()
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  useEffect(() => {
    setTitle(t('referralBot.ownCampaigns.configureCampaign', { campaignName: campaign?.name }) || '')

    return () => setTitle('')
  }, [campaign])

  const isCanStartCampaign = campaign ? canStartCampaign(campaign) : false;
  const campaignStarted = campaign?.status === CampaignStatus.ACTIVE
  const isBudgetNotEnough = Boolean(campaign && hasCampaignEnoughBudget(campaign))
  const disableStartCampaignButton =
    isStartingCampaign || isLoadingCampaign || isRefetching || isBudgetNotEnough

  return (
    <>
      <CampaignCreateStepper activeStep={3} />
      {campaignStarted && (
        <Box
          mt={2}
          mb={2}
        >
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {t('referralBot.startCampaign.campaignStarted')}
          </Alert>
        </Box>
      )}
      {!isRefetching && (
        <StatusTable
          uuid={uuid}
          pickFields={['budget', 'rewardPerUser', 'joinLink']}
        />
      )}
      {campaignStarted && (
        <Box
          mt={2}
          mb={2}
        >
          <h3>{t('referralBot.startCampaign.whatIsNextTitle')}</h3>
          <p>
            <div dangerouslySetInnerHTML={{ __html: t('referralBot.startCampaign.nextStep1') || '' }} />
          </p>
          <p>
            <div dangerouslySetInnerHTML={{ __html: t('referralBot.startCampaign.nextStep2') || '' }} />
          </p>
          <p>
            <div dangerouslySetInnerHTML={{ __html: t('referralBot.startCampaign.nextStep3') || '' }} />
          </p>
        </Box>
      )}
      {isCanStartCampaign && (
        <>
          <Box
            mt={2}
            mb={2}
          >
            <Alert severity="info">{t('referralBot.startCampaign.expalanation')}</Alert>
            {isBudgetNotEnough && (
              <Alert sx={{mt: 2}} severity="warning">{t('referralBot.startCampaign.notEnoughBudget')}</Alert>
            )}
          </Box>
          <Box
            sx={{ p: 2 }}
            display="flex"
            justifyContent="center"
          >
            <LoadingButton
              loading={isLoadingCampaign || isRefetching || isStartingCampaign}
              loadingPosition="start"
              startIcon={<RocketLaunchIcon />}
              variant="contained"
              color="success"
              onClick={() => setConfirmDialog(true)}
              disabled={Boolean(disableStartCampaignButton)}
            >
              {t('referralBot.startCampaign.startAction')}
            </LoadingButton>
          </Box>
        </>
      )}
      <CampaignCreateStepperFooter>
        <Button
          color="inherit"
          sx={{ mr: 1 }}
          component={LinkButton}
          to={ownCampaignFundRoute.id}
          params={{ uuid }}
        >
          {t('referralBot.back')}
        </Button>
      </CampaignCreateStepperFooter>
      {confirmDialog && (
        <ConfirmDialog
          title={t('referralBot.campaignsList.confirmStart') || undefined}
          handleConfirm={() => startCampaignClick()}
          handleCancel={() => setConfirmDialog(false)}
        />
      )}
    </>
  )
}
