import * as React from 'react'

import { TonIcon } from '@components/Icon/TonIcon'
import {Chip, CircularProgress} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { fetchUserProfile } from '@framework/api/referral-bot.api'
import { Link } from '@tanstack/react-router'
import { paymentBalanceBotRoute } from '@components/ReferralBot/Payment/router'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {meCampaignsListBotRoute} from "@components/ReferralBot/Me/router";
import Box from "@mui/material/Box";

export const BalanceHeader = () => {
  const { data: userProfile, isLoading } = useQuery({
    queryKey: ['user_profile'],
    queryFn: () => fetchUserProfile(),
  })

  const pendingPayment = !!userProfile?.hasPendingPayment
  const label = isLoading ? '...' : userProfile?.availableBalance

    return (
    <>
        <Link to={paymentBalanceBotRoute.id}>
            <Box
                sx={{
                    display: 'inline-block',
                    position: 'relative'
                }}
            >
                <Chip
                    clickable
                    variant="filled"
                    color="info"
                    icon={<TonIcon sx={{ width: '30px', height: '30px' }} />}
                    label={label}
                    sx={{
                        color: pendingPayment ? 'lightgrey' : 'white',
                        padding: '0 5px 0 0',
                        '& .MuiChip-label': {
                            overflow: 'visible',
                            fontWeight: 700,
                        },
                    }}
                />
                {pendingPayment && (
                    <Box sx={{position: 'absolute', left: 0, top: 0}} display="inline"><GradientCircularProgress /></Box>
                )}
            </Box>
        </Link>
        {userProfile?.me && (
            <Link to={meCampaignsListBotRoute.id}>
                <Chip
                    clickable
                    variant="filled"
                    color="info"
                    icon={<ManageAccountsIcon sx={{ width: '30px', height: '30px' }} />}
                    // label={label}
                    sx={{
                        m: 2,
                        padding: '0 5px 0 0',
                        '& .MuiChip-label': {
                            overflow: 'visible',
                            fontWeight: 700,
                        },
                    }}
                />
            </Link>
        )}

    </>
  )
}

function GradientCircularProgress() {
    return (
        <React.Fragment>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#e01cd5" />
                        <stop offset="100%" stopColor="#1CB5E0" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={32} thickness={2}/>
        </React.Fragment>
    );
}
