import { Route } from '@tanstack/react-router'
import { List } from '@components/ReferralBot/JoinedCampaign/List'
import { Details } from '@components/ReferralBot/JoinedCampaign/Details'

import { referralBotRoute } from '../router'

export const joinedCampaignListBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/joined-campaigns/list',
  component: List,
})

export const joinedCampaignDetailsRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/joined-campaigns/joined-campaigns/details/$uuid',
  component: Details,
})
