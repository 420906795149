import * as React from 'react'
import { t } from 'i18next'

import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import List from '@mui/material/List'
import { Avatar, Button, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import CampaignIcon from '@mui/icons-material/Campaign'
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box'
import { statusToTranslatableKey } from '@components/ReferralBot/helpers/CampaignStatus'
import { VertMenu } from '@components/ReferralBot/VertMenu'
import MenuItem from '@mui/material/MenuItem'
import { LinkButton } from '@components/LinkButton'
import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import DoneIcon from '@mui/icons-material/Done'
import LinkIcon from '@mui/icons-material/Link'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart'
import {
  ownCampaignConnectBotRoute,
  ownCampaignEditBotRoute,
  ownCampaignFinishRoute,
  ownCampaignFundRoute,
  ownCampaignStartRoute,
  ownCampaignStatusRoute,
} from '@components/ReferralBot/OwnCampaign/router'
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin'
import { formatCurrency } from '@components/ReferralBot/helpers'
import Typography from '@mui/material/Typography'

interface Props {
  campaigns: CampaignDto[]
}

export function ListAsTable({ campaigns }: Props) {
  const disableCampaignStart = (campaign: CampaignDto) => {
    return !campaign.isBotConnected || campaign.status !== CampaignStatus.INACTIVE || Number(campaign.budget) < campaign.minBudget
  }
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Box key="actions">
        <ListItem>
          <ListItemSecondaryAction>
            <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
              {t('referralBot.campaignsList.actions')}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
      </Box>
      {campaigns.map((item) => (
        <Box key={item.uuid}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <CampaignIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={
                <>
                  {t('referralBot.campaignsList.status')}: {t(statusToTranslatableKey(item.status))},{' '}
                  {t('referralBot.campaignsList.numberOfReferrers')}: {item.numberOfReferrers}, {t('referralBot.campaignsList.numberOfReferrals')}:{' '}
                  {item.numberOfReferrals}, {t('referralBot.campaignsList.budget')}: {formatCurrency(item.budget)}
                </>
              }
            />
            <ListItemSecondaryAction>
              <VertMenu>
                <MenuItem>
                  <Button
                    component={LinkButton}
                    startIcon={<SettingsIcon />}
                    to={ownCampaignEditBotRoute.id}
                    params={{ uuid: item.uuid }}
                    variant="text"
                    color="primary"
                    // disabled={item.status !== CampaignStatus.INACTIVE}
                  >
                    {t('referralBot.campaignsList.change')}
                  </Button>
                </MenuItem>
{/*                <MenuItem>
                  <Button
                    component={LinkButton}
                    startIcon={<LinkIcon />}
                    variant="text"
                    color="primary"
                    to={ownCampaignConnectBotRoute.id}
                    params={{ uuid: item.uuid }}
                    disabled={item.isBotConnected}
                  >
                    {t('referralBot.campaignsList.connectBot')}
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    component={LinkButton}
                    startIcon={<CurrencyBitcoinIcon />}
                    variant="text"
                    color="primary"
                    to={ownCampaignFundRoute.id}
                    params={{ uuid: item.uuid }}
                    disabled={!item.isBotConnected || item.status === CampaignStatus.FINISHED}
                  >
                    {t('referralBot.campaignsList.fundCompany')}
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    startIcon={<RocketLaunchIcon />}
                    variant="text"
                    color="primary"
                    component={LinkButton}
                    to={ownCampaignStartRoute.id}
                    params={{ uuid: item.uuid }}
                    disabled={disableCampaignStart(item)}
                  >
                    {t('referralBot.campaignsList.startCampaign')}
                  </Button>
                </MenuItem>*/}
                <MenuItem>
                  <Button
                    startIcon={<DoneIcon />}
                    variant="text"
                    color="primary"
                    component={LinkButton}
                    to={ownCampaignFinishRoute.id}
                    params={{ uuid: item.uuid }}
                    disabled={item.status !== CampaignStatus.ACTIVE}
                  >
                    {t('referralBot.campaignsList.finishCampaign')}
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    component={LinkButton}
                    startIcon={<StackedLineChartIcon />}
                    to={ownCampaignStatusRoute.id}
                    params={{ uuid: item.uuid }}
                    variant="text"
                    color="primary"
                  >
                    {t('referralBot.status')}
                  </Button>
                </MenuItem>
              </VertMenu>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider
            variant="inset"
            component="li"
          />
        </Box>
      ))}
    </List>
  )
}
