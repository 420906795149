import * as React from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { t } from 'i18next'
import { TopActions } from '@components/ReferralBot/TopActions'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'

interface CreateCampaignStep {
  title: string;
}

const steps: CreateCampaignStep[] = [
  { title: 'referralBot.createCampaignSteps.baseInfo' },
  { title: 'referralBot.createCampaignSteps.connectBot' },
  { title: 'referralBot.createCampaignSteps.fundCampaign' },
  { title: 'referralBot.createCampaignSteps.startCampaign' },
];

interface Props {
  activeStep: number;
}

export const CampaignCreateStepper = ({activeStep}: Props) => {
    return (
      <>
        <TopActions>
          <Button
            component={LinkButton}
            startIcon={<ArrowBackIcon />}
            to={ownCampaignListBotRoute.id}
            variant="outlined"
            color="primary"
          >
            {t('referralBot.createCampaignSteps.backToCampaigns')}
          </Button>
        </TopActions>
        <Stepper activeStep={activeStep} sx={{pt: 2, pb: 8}} alternativeLabel>
          {steps.map((step, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>
                  {t(step.title)}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </>
    );
}
