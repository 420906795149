import { useEffect } from 'react'
import { useAtom } from 'jotai/index'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'

export const useHeaderTitleHook = () => {
  const [, setTitle] = useAtom(headerTitleAtom)

  useEffect(() => {
    return () => {
      setTitle('')
    }
  }, [])

  return (title) => setTitle(title)
}
