import React from 'react'
import { t } from 'i18next'
import { useAtom } from 'jotai/index'

import { useParams } from '@tanstack/react-router'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { TopActions } from '@components/ReferralBot/TopActions'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { joinedCampaignDetailsRoute, joinedCampaignListBotRoute } from '@components/ReferralBot/JoinedCampaign/router'
import { useQuery } from '@tanstack/react-query'
import { fetchReferrerDetails } from '@framework/api/referral-bot.api'
import { CustomTable } from '@components/ReferralBot/Table/CustomTable'
import { referrerDetailsToTableRows } from '@components/ReferralBot/helpers/custom-table.helper'

export const Details = () => {
  const {uuid} = useParams<typeof joinedCampaignDetailsRoute>({
    from: joinedCampaignDetailsRoute.id
  })

  const { data: referrerDetails, isLoading: isFetchingReferrerDetails } = useQuery({
    queryKey: ['referrer_details', uuid],
    queryFn: () => fetchReferrerDetails(uuid),
  })

  const [, setTitle] = useAtom(headerTitleAtom)
  setTitle(t('referralBot.joinedCampaignDetails.title', { campaignName: referrerDetails?.campaignName }) || '')

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={joinedCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      {referrerDetails && <CustomTable rows={referrerDetailsToTableRows(referrerDetails)} />}
    </>
  )
}
