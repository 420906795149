import { Route } from '@tanstack/react-router'

import { referralBotRoute } from '../router'
import {CampaignsList} from "@components/ReferralBot/Me/CampaignsList";
import {CampaignDetails} from "@components/ReferralBot/Me/CampaignDetails";

export const meCampaignsListBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/me/campaigns',
  component: CampaignsList,
})

export const meCampaignDetailsBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/me/campaigns/$uuid',
  component: CampaignDetails,
})
