import { Route } from '@tanstack/react-router'

import { referralBotRoute } from '../router'
import { HomeScreen } from './HomeScreen'

export const homeScreenBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/',
  component: HomeScreen,
})
