import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {BOT_LINK} from "@framework/constants";

export default function Pricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          // width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Pricing
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{mt: 3}}>
            Unlock the full potential of your Telegram channel with our competitive and straightforward pricing model. Enjoy maximum returns with minimal investment, as <b>90% of your campaign budget is dedicated to rewards</b>, and our platform fee is a modest 10%. Invest in your channel's growth with confidence and see real results with every TON spent.
        </Typography>
          <Button sx={{mt: 3}} href={BOT_LINK} target="_blank" variant="outlined" color="primary">
              Try it out
          </Button>
      </Box>
    </Container>
  );
}
