import React from 'react'
import { useAtom } from 'jotai/index'
import { t } from 'i18next'

import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { useQuery } from '@tanstack/react-query'
import {fetchCampaignsMe, fetchReferrers} from '@framework/api/referral-bot.api'
import { ListAsTable } from '@components/ReferralBot/JoinedCampaign/ListAsTable'
import { AlertMessage } from '@components/ReferralBot/Alert'
import Box from "@mui/material/Box";
import {Avatar, Button, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction} from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import ListItemText from "@mui/material/ListItemText";
import {statusToTranslatableKey} from "@components/ReferralBot/helpers/CampaignStatus";
import {canWithdrawReward} from "@components/ReferralBot/helpers/referrer.helper";
import {VertMenu} from "@components/ReferralBot/VertMenu";
import MenuItem from "@mui/material/MenuItem";
import {LinkButton} from "@components/LinkButton";
import InfoIcon from "@mui/icons-material/Info";
import {joinedCampaignDetailsRoute} from "@components/ReferralBot/JoinedCampaign/router";
import List from "@mui/material/List";
import {formatDateTime} from "@components/ReferralBot/helpers/date.helper";
import {meCampaignDetailsBotRoute} from "@components/ReferralBot/Me/router";

export const CampaignsList = () => {
  const [, setTitle] = useAtom(headerTitleAtom)
  setTitle('Campaigns list [ME]')

  const { data, isLoading } = useQuery({
    queryKey: ['campaigns_list_me'],
    queryFn: () => fetchCampaignsMe(),
  })

  const campaigns = data || []

  return (
    <>
      {campaigns.length > 0 && (
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {campaigns.map((item) => (
                <Box key={item.uuid}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <CampaignIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={item.name}
                        secondary={
                          <>
                            {t('referralBot.campaignsList.status')}: {t(statusToTranslatableKey(item.status))},{' '}
                            {t('referralBot.joinedCampaigns.numberOfReferrals')}: {item.numberOfReferrals},
                            Agents: {item.numberOfReferrers},
                            Budget: {item.budget},
                            Created: {formatDateTime(item.createdAt)},
                            Started: {formatDateTime(item.startedAt)},
                            Finished: {formatDateTime(item.finishedAt)},
                          </>
                        }
                    />
                    <ListItemSecondaryAction>
                      <VertMenu>
                        <MenuItem>
                          <Button
                              component={LinkButton}
                              startIcon={<InfoIcon />}
                              to={meCampaignDetailsBotRoute.id}
                              params={{ uuid: item.uuid }}
                              variant="text"
                              color="primary"
                          >
                            Details
                          </Button>
                        </MenuItem>
                      </VertMenu>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider
                      variant="inset"
                      component="li"
                  />
                </Box>
            ))}
          </List>
      )}
    </>
  )
}
