import * as React from 'react'
import { PaletteMode } from '@mui/material'
import Divider from '@mui/material/Divider'
import { createTheme } from '@mui/material/styles'
import Pricing from './Pricing'
import Features from './Features'
import FAQ from './FAQ'
import Hero from '@components/Site/Landing/Hero'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { BOT_LINK } from '@framework/constants'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import Container from '@mui/material/Container'

export default function LandingPage() {
    const [mode, setMode] = React.useState<PaletteMode>('light');
    const defaultTheme = createTheme({ palette: { mode } });

    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
    };

    return (
        <>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pb: { xs: 8, sm: 12 },
            }}
          >
            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
              <Typography
                variant="h1"
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                }}
              >
                Boost Your
                <Typography
                  component="span"
                  variant="h1"
                  sx={{
                    fontSize: 'clamp(3rem, 10vw, 4rem)',
                    color: (theme) =>
                      theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                  }}
                >
                  &nbsp;Telegram Channel
                </Typography>
              </Typography>
              <Typography
                textAlign="center"
                color="text.secondary"
                sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
              >
                Unlock the power of a proven referral system, now available for both <b>private and public Telegram channels</b>.
              </Typography>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignSelf="center"
                spacing={1}
                useFlexGap
                sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
              >
                <Button href={BOT_LINK} target="_blank" startIcon={<RocketLaunchIcon />} variant="outlined" color="primary">
                  Start now
                </Button>
              </Stack>
            </Stack>
          </Container>
          <Features />
          {/*<Testimonials />*/}
          <Divider />
          {/*<Highlights />*/}
          {/*<Divider />*/}
          <Pricing />
        </>
    );
}
