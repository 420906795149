import React from 'react'

import { useSnackbar } from 'notistack'

import {Box, Button} from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {startCampaign, withdrawCampaignBudgetMe} from '@framework/api/referral-bot.api'
import {CampaignMeDto} from "@components/ReferralBot/Type/CampaignMeDto";
import {ConfirmDialog} from "@components/ReferralBot/Confirm";
import {getErrorMessage} from "@components/ReferralBot/helpers";

interface Props {
    campaign: CampaignMeDto
}

export const WithdrawBudgetWidget = ({campaign}: Props) => {
  const [confirmDialog, setConfirmDialog] = React.useState(false)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: withdrawCampaignBudgetMeMutate, isPending: isWithdrawing } = useMutation({
    mutationFn: withdrawCampaignBudgetMe,
  })

  const onWithdrawClick = () => {
    setConfirmDialog(false)

    withdrawCampaignBudgetMeMutate(campaign.uuid, {
      onSuccess: async () => {
        enqueueSnackbar('Success!', { variant: 'success' })

        await queryClient.invalidateQueries({
          queryKey: ['campaign_me', campaign.uuid]
        })
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  if (!campaign.canWithdrawBudget) {
    return null;
  }

  return (
    <Box sx={{p: 3}}>
      <Button
          variant="contained"
          color="warning"
          disabled={isWithdrawing}
          onClick={() => setConfirmDialog(true)}
      >
        Withdraw Budget
      </Button>
      {confirmDialog && (
          <ConfirmDialog
              handleConfirm={() => onWithdrawClick()}
              handleCancel={() => setConfirmDialog(false)}
          />
      )}
    </Box>
  )
}
